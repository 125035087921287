<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <div class="pa-2">
          <ls-client-drop-down
            @clientselected="selectNewClient"
          ></ls-client-drop-down>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="pa-2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scroll-x-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="вкажіть період"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearDates"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
              :first-day-of-week="1"
              locale="uk-ua"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <ls-offer-item
        class="offeritem"
        v-bind:offer="offer"
        v-for="offer in offers"
        :key="offer.id"
        @editOffer="editOffer"
        @copyOffer="copyOffer"
        @publishOffer="publishOffer"
      ></ls-offer-item>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function addDays(curdate, days) {
  var date = new Date(curdate);
  date.setDate(date.getDate() + days);
  return date.toISOString();
}
export default {
  name: "ls-offer-list",
  watch: {
    dates() {
      if (this.dates.length > 1) {
        this.datesinfo = this.dates.map((e) => {
          return e;
        });
        this.datesinfo.sort();
        this.dateinrequest = [this.datesinfo[0], addDays(this.datesinfo[1], 1)];
        this.menu = false;
      }
      if (this.dates.length == 0) {
        this.dateinrequest = [];
        this.datesinfo = [];
      }
    },
    datesinfo(newVal, oldValue) {
      if (!arraysEqual(newVal, oldValue)) {
        this.fetchOffers();
      }
    },
  },
  computed: {
    dateRangeText() {
      return this.datesinfo.join(" ~ ");
    },
  },
  data() {
    return {
      menu: false,
      dates: [],
      datesinfo: [],
      dateinrequest: [],
      offers: [],
      currentClient: "",
    };
  },
  methods: {
    clearDates() {
      this.dates = [];
    },
    selectNewClient(client) {
      this.currentClient = client;
      this.fetchOffers();
    },
    editOffer(currentOffer) {
      this.$emit("editOffer", currentOffer);
    },
    copyOffer(currentOffer) {
      this.$emit("copyOffer", currentOffer);
    },
    publishOffer(currentOffer) {
      this.$emit("publishOffer", currentOffer);
    },
    async fetchOffers() {
      let query = {};
      if (this.datesinfo.length > 0) {
        query.datefrom = this.dateinrequest[0];
        query.dateto = this.dateinrequest[1];
      }
      if (this.currentClient) {
        query.clientId = this.currentClient.id;
      }
      const offersRequest = await axios.get(
        process.env.VUE_APP_API_ADDRESS + "/api/offers/offers",
        {
          headers: {
            Authorization: localStorage.getItem("lstoken"),
          },
          params: query,
        }
      );
      this.offers = offersRequest.data.offer;
    },
  },
  mounted() {
    this.fetchOffers();
  },
};
</script>

<style scoped>
.offersholder {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.filterholder {
  width: 100%;
}
</style>