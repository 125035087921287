<template>
  <div>
    <h1>Робочий стіл</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  beforeMount() {
    this.$emit("checkAuth", 'main page')
  },
};
</script>

<style scoped>
</style>
