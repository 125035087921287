<template>
  <v-card hover class="mx-auto ma-3" max-width="344">
    <v-card-text>
      <div>Код в 1С: {{ client.dbcode }}</div>
      <p class="text-h4 text--primary">
        {{ getFirstName() }} <br />
        {{ getLastName() }}
      </p>
      <div class="pt-3 text--primary">
        {{ client.phone }}
      </div>
      <div class="pt-3 text-primary">
        {{ client.email }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="teal accent-4" @click="reveal = true"> Змінити </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%"
      >
        <v-card-text class="pb-0">
          <p>
            <v-text-field
              height="20"
              v-model="client.name"
              label="Ім'я клієнта"
              hide-details="auto"
            ></v-text-field>
          </p>
          <p>
            <v-text-field
              height="20"
              v-model="client.phone"
              label="телефон"
              hide-details="false"
              v-mask="'(###) ###-####'"
            ></v-text-field>
          </p>
          <p>
            <v-text-field
              height="20"
              v-model="client.email"
              label="email"
              hide-details="auto"
            ></v-text-field>
          </p>
          <v-row no-gutters>
            <v-col
              ><v-text-field
                class="mr-3"
                height="20"
                v-model="client.dbcode"
                label="код в 1С"
                hide-details="auto"
              ></v-text-field
            ></v-col>
            <v-col
              ><v-text-field
                class="ml-3"
                height="20"
                v-model="client.discount"
                label="знижка"
                hide-details="auto"
              ></v-text-field
            ></v-col>
          </v-row>
          <p></p>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn text color="teal accent-4" @click="reveal = false">
            Закрити
          </v-btn>
          <v-btn text color="red accent-4" @click="saveChanges()">
            Зберегти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "ls-client-card",
  props: {
    client: {
      type: Object,
    },
  },
  data() {
    return {
      reveal: false,
    };
  },
  methods: {
    getFirstName() {
      if (typeof this.client.name === "undefined") return "";
      let nameArray = this.client.name.split(" ");
      return nameArray[0];
    },
    getLastName() {
      if (typeof this.client.name === "undefined") return "";
      let nameArray = this.client.name.split(" ");
      return nameArray[1];
    },
    async saveChanges() {
      let resp = await axios.put(
        process.env.VUE_APP_API_ADDRESS + "/api/clients/client",
        {
          name: this.client.name,
          phone: this.client.phone,
          email: this.client.email,
          dbcode: this.client.dbcode,
          recordid: this.client.id,
          discount: this.client.discount,
        },
        {
          headers: {
            Authorization: localStorage.getItem("lstoken"),
          },
        }
      );
      if (resp.status == 200) {
        this.reveal = false;
      }
    },
  },
  beforemount: {},
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>