import LSItem from '@/components/LSItem.vue'
import LSItemSelected from '@/components/LSItemSelected.vue'
import LSAuthDialog from '@/components/LSAuthDialog.vue'
import LSDrawer from '@/components/LSDrawer.vue'
import LSSnackbar from '@/components/LSSnackbar.vue'
import LSClientCard from '@/components/LSClientCard.vue'
import LSClientAddition from '@/components/LSClientAddition.vue'
import LSSelectionItems from '@/components/LSSelectionItems.vue'
import LSOfferList from '@/components/LSOfferList.vue'
import LSOfferItem from '@/components/LSOfferItem.vue'
import LSClientDropDown from '@/components/LSClientDropDown.vue'
import LSOfferEdition from '@/components/LSOfferEdition.vue'
import LSOfferCopy from '@/components/LSOfferCopy.vue'
import LSOfferCreation from '@/components/LSOfferCreation.vue'
import LSOfferView from '@/components/LSOfferView.vue'

export default[
    LSItem,
    LSItemSelected,
    LSAuthDialog,
    LSDrawer,
    LSSnackbar,
    LSClientCard,
    LSClientAddition,
    LSSelectionItems,
    LSOfferCreation,
    LSOfferEdition,
    LSOfferCopy,
    LSOfferList,
    LSOfferItem,
    LSClientDropDown,
    LSOfferView,
]