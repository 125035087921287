<template>
  <div class="wrapper">
    <slot></slot>
    <v-row>
      <v-col>
        <div class="listholer">
          <div>
            <v-text-field
              v-model="searchPhraseMethod"
              label="почніть пошук тут..."
              hide-details="auto"
              class="pb-5"
              filled
              clearable
              @click:clear="onSearchClear"
            ></v-text-field>
          </div>
          <vue-custom-scrollbar @ps-scroll-y="callonscroll">
            <v-container class="list holderitems pt-5">
              <drag
                v-for="item in items"
                :data="item"
                class="item"
                :key="item.id"
                ><ls-item v-bind:item="item"
              /></drag>
            </v-container>
          </vue-custom-scrollbar>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <div class="grey-text text-h6 mt-2 mb-2">Відібрані позиції</div>
        <vue-custom-scrollbar>
          <drop-list
            :items="itemsinpropose"
            class="listselected holderitems pt-5 pb-5"
            @insert="onInsert"
            @reorder="$event.apply(itemsinpropose)"
          >
            <template v-slot:item="{ item }">
              <drag class="item" :key="item.id">
                <ls-item-selected
                  @removeFromList="removeFromList"
                  v-bind:item="item"
                />
              </drag>
            </template>
            <template v-slot:feedback="{ data }">
              <div class="item feedback" :key="data.id"></div>
            </template>
          </drop-list>
        </vue-custom-scrollbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd";
import axios from "axios";
export default {
  name: "ls-selection-items",
  components: {
    Drag,
    DropList,
  },
  props: {
    offerItems: {
      type: Object,
    },
  },
  data() {
    return {
      wow: "test",
      searchPhrase: "",
      items: [],
      itemsinpropose: [],
      ready: false,
      typingTimer: null,
      doneTypingInterval: 300,
      currentPage: 1,
      completeItemsReached: false,
    };
  },
  watch: {
    offerItems() {
      this.updateItems();
    },
  },
  beforeMount() {
    this.updateItems();
  },
  computed: {
    searchPhraseMethod: {
      get() {
        //this function will determine what is displayed in the input
        return this.searchPhrase;
      },
      set(newVal) {
        //this function will run whenever the input changes
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
          this.ready = true;
          this.currentPage = 1;
          this.completeItemsReached = false;
          this.showItems();
        }, this.doneTypingInterval);
        if (newVal == null) {
          newVal = "";
        }
        this.ready = false;
        this.searchPhrase = newVal;
      },
    },
  },
  methods: {
    callonscroll(el) {
      //console.log(el)
      //console.log(el.target.scrollHeight+" client height "+el.target.clientHeight+" offset "+el.target.scrollTop)
      if (
        el.target.scrollHeight ==
        el.target.clientHeight + el.target.scrollTop
      ) {
        if (this.completeItemsReached == false) {
          this.currentPage++;
          this.liveSearch();
        }
        //console.log("you reached bottom of the div");
      }
    },
    updateItems() {
      this.itemsinpropose = [];
      //console.log(this.offerItems.items)
      this.fillSelectedItems();
    },
    async getItemsFromIstok(itemId) {
      const connectionString =
        process.env.VUE_APP_ISTOK_ADDRESS + "/api/v1/toys?code=" + itemId;
      try {
        const response = await axios.get(connectionString, {
          headers: {
            "x-access-token":
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InZvbG9keW15ci5zaHlua2Fyb3YifQ.VWIINqStn-pXrqoJZ3FHhP7I2N11fp-_ifls-PEsdhg",
          },
        });
        //console.log(response.data.data[0])
        return response.data.data[0];
      } catch (err) {
        console.log(err);
      }
    },
    async fillSelectedItems() {
      if (this.offerItems) {
        this.itemsinpropose = this.offerItems.items.map((element) => {
          return {
            code: element.code,
            articul: element.articul,
            name: element.name,
            price: element.price,
            pricemiddle: element.pricemiddleuah,
            priceusd: element.priceoutusd,
            image: element.images,
            id: element.id,
            maximumquantity: element.maxquantity,
          };
        });
        // for (let i = 0; i < this.offerItems.items.length; i++) {
        //   const element = this.offerItems.items[i];
        //   this.itemsinpropose.push({
        //     code: element.code,
        //     articul: element.articul,
        //     name: element.name,
        //     price: element.price,
        //     image: element.images,
        //     id: element.id,
        //     maximumquantity: element.maxquantity,
        //   });
        // }
      }
    },
    removeFromList(myObj) {
      let count = 0;
      this.itemsinpropose.forEach((element) => {
        if (element.code == myObj.code) this.itemsinpropose.splice(count, 1);
        count++;
      });
    },
    onSearchClear() {
      this.showItems();
    },
    onInsert(event) {
      this.itemsinpropose.splice(event.index, 0, event.data);
    },
    callFromChild(mess) {
      this.wow = mess.title;
    },
    showItems() {
      this.items = [];
      if (this.ready == true && this.searchPhrase.length > 2) {
        this.liveSearch();
      }else{
        this.items = [];
      }
    },
    async liveSearch() {
      //prepare search phrase
      let wordsArray = this.searchPhrase.split(" ");
        let someSearchQuery = '';
        for (var i = 0; i < wordsArray.length; i++) {
          if (wordsArray[i].length > 0) {
            // if (someSearchQuery.length > 1) {
            //   someSearchQuery += " & ";
            // }
            someSearchQuery += wordsArray[i] + "%2C"
          }
        }
      //console.log(someSearchQuery)

      const connectionString =
        process.env.VUE_APP_ISTOK_ADDRESS +
        "/api/v1/searching?page=" +
        this.currentPage +
        "&limit=15&q=" +
        someSearchQuery;
      try {
        const response = await axios.get(connectionString, {
          headers: {
            "x-access-token":
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InZvbG9keW15ci5zaHlua2Fyb3YifQ.VWIINqStn-pXrqoJZ3FHhP7I2N11fp-_ifls-PEsdhg",
          },
        });
        //console.log(response.data);
        // this.items = response.data.data.map(function (element) {
        //   return {
        //     code: element.ourarticul,
        //     articul: element.cnarticul,
        //     name: element.ourname,
        //     price: element.priceoutuah,
        //     image: element.images,
        //     id: element.id,
        //   };
        // });
        if (response.data.data.length == 0) {
          this.completeItemsReached = true;
        }
        response.data.data.forEach((element) => {
          this.items.push({
            code: element.ourarticul,
            articul: element.cnarticul,
            name: element.ourname,
            price: element.priceoutuah,
            pricemiddle: element.pricemiddleuah,
            priceusd: element.priceoutusd,
            image: element.images,
            id: element.id,
            maximumquantity: 0,
            updated:false,
          });
        });
        this.isPostLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.holderitems {
  display: block;
  max-height: 70vh;
}

.wrapper {
  max-height: 100hv;
  .listselected {
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 50px;
    .item {
      align-items: center;
      justify-content: center;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .list {
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
    .item {
      align-items: center;
      justify-content: center;
      margin: 5px;

      &.feedback {
        background-color: rgb(255, 220, 220);
        border-radius: 5px;
      }

      &.drag-image {
        background-color: rgb(220, 255, 220);
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
