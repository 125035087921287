<template>
  <v-snackbar
    v-model="snackbar"
    :multi-line="true"
    :timeout="timeout"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn color="pink" icon v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close-box-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "ls-snackbar",
  data: () => ({
    snackbar: false,
    text: "",
    timeout: 3000,
  }),
  methods: {
    showSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
  },
};
</script>

<style>
</style>