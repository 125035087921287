const axios = require("axios")

class AuthentificationController {
    constructor() {
        this.user = {}
        this.authentificated = false
    }

    static async register(name, login, partner, password, secret){
        try {
            let apireq = await axios.post(process.env.VUE_APP_API_ADDRESS+'/api/user/registration', {
                "name": name,
                "email": login,
                "password": password,
                "partnerid": partner,
                "secret": secret,
                "expiration": "48h"
            })
            if(apireq.status == 200){
                return true
            }
        } catch (err) {
            return false
        }
    }

    static async login(login, password) {
        try {
            let apireq = await axios.post(process.env.VUE_APP_API_ADDRESS+'/api/user/login', {
                "email": login,
                "password": password
            })
            localStorage.setItem('lstoken', 'Bearer ' + apireq.data.token);
            let nameArray = apireq.data.name.split(' ')
            let shortName = nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase()
            this.user = {
                name: apireq.data.name,
                short: shortName,
                partnerid: apireq.data.partnerid
            }
            this.authentificated = true
        } catch (err) {
            this.user = {
                name: '',
                short: ''
            }
            localStorage.setItem('lstoken', '');
            this.authentificated = false
            return false
        }
    }

    static async checkAuth() {
        let tokenPauload = function (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }
        let key = localStorage.getItem('lstoken')
        if (key != null && key.length <= 0) {
            return false
        } else {
            try{
                await axios.get(process.env.VUE_APP_API_ADDRESS+'/api/user/auth', {
                    headers: {
                        Authorization: key
                    }
                })
                let curtoken = key.split(' ')[1]
                let payload = tokenPauload(curtoken)
                let nameArray = payload.name.split(' ')
                let shortName = nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase()
                this.user = {
                    name: payload.name,
                    short: shortName,
                    partnerid: payload.partnerid
                }
                this.authentificated = true
                return true
            }catch{
                this.user = {
                    name: '',
                    short: '',
                    partnerid: ''
                }
                localStorage.setItem('lstoken', '');
                this.authentificated = false
                return false
            }
        }
    }
}

export default AuthentificationController