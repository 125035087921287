<template>
  <v-card
    class="mx-auto mt-5"
    color="lighten--5"
    max-width="430"
    min-width="430"
    hover
  >
    <v-btn icon class="btn_close" @click="removeFromList">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-row no-gutters>
      <v-col>
        <div class="pa-3">
          <v-img
            circle
            :aspect-ratio="6 / 4"
            :src="getFirstImage()"
          ></v-img></div
      ></v-col>
      <v-col>
        <div class="text-h6">{{ item.code }}</div>
        <div class="text-h7">{{ this.getName() }}</div>
        <div class="text-h5">ціна:{{ this.getPrice() }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-end text-center subtitle-1">
          Доступна кількість для замовлення
        </div>
      </v-col>
      <v-col>
        <v-card-actions>
          <v-btn
            class="mx-2"
            @click="minusOrderQuantity"
            fab
            dark
            small
            color="red"
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>

          <div :key="componentKey" class="text-h5 pa-3">{{ item.maximumquantity }}</div>

          <v-btn
            class="mx-2"
            @click="addOrderQuantity"
            fab
            dark
            small
            color="green"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import AuthentificationController from "@/controllers/AuthentificationController";
export default {
  name: "ls-item-selected",
  props: {
    item: Object,
  },
  data() {
    return {
      componentKey : 1,  
    };
  },
  async created() {
    if (this.item.maximumquantity == 0) this.item.maximumquantity = 1;
  },
  methods: {
    getMaxQuantity() {
      return this.item.maximumquantity;
    },
    removeFromList() {
      this.$emit("removeFromList", this.item);
    },
    addOrderQuantity() {
      this.item.maximumquantity++;
      this.componentKey++  
    },
    minusOrderQuantity() {
      if (this.item.maximumquantity > 1) {
        this.item.maximumquantity--;
        this.componentKey--;  
      }
    },
    getFirstImage() {
      return "https://xn--80axep4eb.xn--j1amh/public/images/" + this.item.image[0];
    },
    getPrice() {
        let pricestr = "";
        if (AuthentificationController.user.partnerid == 2) {
            if (this.item.priceusd == null) {
                pricestr = this.addZeroes(this.item.pricemiddle) + " грн.";                                        
            } else {
                pricestr = this.addZeroes(this.item.priceusd) + " usd.";
            }
                              
        } else {
            pricestr = this.addZeroes(this.item.price) + " грн.";
        }
        return pricestr;
//      let pricestr = this.addZeroes(this.item.price) + " грн.";
//      return pricestr;
    },
    addZeroes(num) {
      if (num == null) num = 0;
      return num.toFixed(Math.max(((num + "").split(".")[1] || "").length, 2));
    },
    getOffsetprice() {
      let pricelenght = this.addZeroes(this.item.price).toString().length * 10;
      return pricelenght;
    },
    getName() {
      let shortName = "";
      if (this.item.name.length >= 60) {
        shortName = this.item.name.substring(0, 60) + "...";
      } else {
        shortName = this.item.name;
      }
      return shortName;
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.buttonsholder {
  display: flex;
  border: 1px solid greenyellow;
  align-items: center;
  align-content: center;
}
.btn_close {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
