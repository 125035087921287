<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto"
      color="grey lighten-4"
      max-width="320"
      min-width="280"
      elevation="5"
      hover
    >
      <v-img
        :class="{ img: isActive }"
        :aspect-ratio="6 / 4"
        :src="getFirstImage()"
      >
        <v-expand-transition>
          <div
            v-if="hover"
            class="
              flex-column
              pl-2
              pr-2
              pt-2
              transition-fast-in-slow-out
              primary
              darken-2
              v-card--reveal
              white--text
            "
            style="height: 100%; opacity: 0.9"
          >
            <div class="text-h6" style="text-align: center">
              {{ item.code }}
            </div>
            <v-divider dark></v-divider>
            <div class="text-h6" style="text-align: center">
              ціна: {{ getPrice() }}
            </div>
            <v-divider dark></v-divider>
            <div class="text-h7" style="text-align: center">
              {{ getName() }}
            </div>
            <div class="text-h6" style="text-align: center">
              Залишок: {{ getStringQuantity() }} шт.
            </div>
          </div>
        </v-expand-transition>
      </v-img>
    </v-card>
  </v-hover>
</template>

<script>
import axios from "axios";
import AuthentificationController from "@/controllers/AuthentificationController";
export default {
  name: "ls-item",
  props: {
    item: {
      type: Object,
    },
  },
  watch: {
    datarecived() {
        if (this.item.maximumquantity == 0) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
    },
  },
  mounted(){
    if (this.item.updated == false) {
        console.log('213')
        this.getStockQuantity(this.item.code);
        this.item.updated = true
      }
  },
  data() {
    return {
      datarecived: false,
      opacity: 1,
      isActive: false,
    };
  },
  methods: {
    getFirstImage() {
      try {
        return "https://istok.cyou/api/v1/images/" + this.item.image[0];
      } catch (err) {
        console.log(err);
      }
    },
    getPrice() {
        let pricestr = "";
//        console.log(this.item.pricemiddle);
//        console.log(this.item.priceusd);
//        console.log(this.item.price);
        if (AuthentificationController.user.partnerid == 2) {
            if (this.item.priceusd == null) {
                 pricestr = this.addZeroes(this.item.pricemiddle) + " грн.";
            } else {
                 pricestr = this.addZeroes(this.item.priceusd) + " usd.";
            }
        } else {
            pricestr = this.addZeroes(this.item.price) + " грн.";
        }
        return pricestr;
//      let pricestr = this.addZeroes(this.item.price) + " грн.";
//      return pricestr;
    },
    addZeroes(num) {
      if (num == null) num = 0;
      return num.toFixed(Math.max(((num + "").split(".")[1] || "").length, 2));
    },
    getOffsetprice() {
      let pricelenght = this.addZeroes(this.item.price).toString().length * 10;
      return pricelenght;
    },
    getName() {
      let shortName = this.item.name; //.substring(0, 30);
      return shortName;
    },
    getStringQuantity() {
      return this.item.maximumquantity;
    },
    getUserStockQuantity(mydata) {
      if (this.datarecived == true) return this.item.quantityonstore;
      var parsedobj = JSON.parse(JSON.stringify(mydata));
      let quant = parsedobj.data.find((item) => {
        if (AuthentificationController.user.partnerid == item.partner) {
          return item;
        }
      });
      if (quant == null) {
        this.item.quantityonstore = 0;
        this.item.maximumquantity = this.item.quantityonstore;
        this.datarecived = true;
        return 0;
      }
      this.item.quantityonstore = quant.quantity;
      this.item.maximumquantity = this.item.quantityonstore;
      this.datarecived = true;
      return quant.quantity;
    },
    async getStockQuantity(itemCode) {
      if (this.datarecived == true) return this.item.quantityonstore;
      const connectionString =
        process.env.VUE_APP_ISTOK_ADDRESS +
        "/api/v1/stockbalance/item/" +
        itemCode;
      try {
        const response = await axios.get(connectionString, {
          headers: {
            "x-access-token":
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InZvbG9keW15ci5zaHlua2Fyb3YifQ.VWIINqStn-pXrqoJZ3FHhP7I2N11fp-_ifls-PEsdhg",
          },
        });
        this.getUserStockQuantity(response.data);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.img {
  filter: grayscale(1);
}
</style>
