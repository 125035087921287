<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Новий клієнт </v-card-title>
        <v-card-text class="pb-0">
          <p>
            <v-text-field
              height="20"
              v-model="client.name"
              label="Ім'я клієнта"
              hide-details="auto"
              :rules="[rules.required]"
            ></v-text-field>
          </p>
          <p>
            <v-text-field
              height="20"
              v-model="client.phone"
              label="телефон"
              hide-details="false"
              :rules="[rules.required]"
              v-mask="'(###) ###-####'"
            ></v-text-field>
          </p>
          <p>
            <v-text-field
              height="20"
              v-model="client.email"
              label="email"
              hide-details="auto"
              :rules="[rules.email]"
            ></v-text-field>
          </p>
          <v-row no-gutters>
            <v-col
              ><v-text-field
                class="mr-3"
                height="20"
                v-model="client.dbcode"
                label="код в 1С"
                hide-details="auto"
                :rules="[rules.required]"
              ></v-text-field
            ></v-col>
            <v-col
              ><v-text-field
                class="ml-3"
                height="20"
                v-model="client.discount"
                label="знижка"
                hide-details="auto"
                :rules="[rules.required]"
              ></v-text-field
            ></v-col>
          </v-row>
          <p></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeDialog">
            Скасувати
          </v-btn>
          <v-btn color="green darken-1" text @click="saveClient">
            Зберегти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ls-client-addition",
  props: {
    client: {
      type:Object
    },
    dialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Обов'язкове поле",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "введіть правильний email";
        },
      },
    };
  },
  methods: {
    resetForm(){
      //this.resetValidation()
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveClient() {
      this.$emit("saveNewClient", this.client);
    },
  },
};
</script>

<style scoped>
</style>