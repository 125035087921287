<template>
  <v-autocomplete
    v-model="selectedValue"
    clearable
    :items="clients"
    item-text="name"
    hide-no-data
    :loading="isLoading"
    label="Клієнт"
    @change="changeSelection"
    @click:clear="clearSelection"
    placeholder="Оберіть клієнта..."
  ></v-autocomplete>
</template>

<script>
import axios from "axios";
export default {
  name: "ls-client-drop-down",
  props: {
    preparedClient: {
      type: Object,
    },
  },
  data() {
    return {
      clients: [],
      ready: false,
      isLoading: false,
      currentClient: "",
      currIndex: 0,
      selectedValue: 0,
    };
  },
  watch: {
    preparedClient(newVal) {
      if (newVal) {
        this.currIndex = this.clients.findIndex((element) => {
          if (element.id == newVal.id) return true;
        });
      }
      this.selectedValue = this.clients[this.currIndex];
    },
  },
  methods: {
    clearSelection(){
      console.log('123')
      this.$emit("clientselected",null)
    },
    changeSelection(newVal) {
      if (newVal) {
        console.log(newVal)
        this.currentClient = this.getClientByName(newVal);
        this.$emit("clientselected",this.currentClient)
      }
    },
    getClientByName(clientName) {
      return this.clients.find((item) => {
        if (item.name == clientName) return item;
      });
    },
    getClientIndex() {
      return this.currIndex;
    },
    async loadEntries() {
      this.isLoading = true;
      let path = "";
      path = process.env.VUE_APP_API_ADDRESS + "/api/clients/clients";
      let clientsreq = await axios.get(path, {
        headers: {
          authorization: localStorage.getItem("lstoken"),
        },
      });
      if (clientsreq.status == 200) {
        this.clients = clientsreq.data;
        if (this.preparedClient != null) {
          this.currIndex = this.clients.findIndex((element) => {
            if (element.id == this.preparedClient.id) return true;
          });
          this.selectedValue = this.clients[this.currIndex];
          this.currentClient = this.getClientByName(this.selectedValue.name);
        }
        this.isLoading = false;
      }
    },
  },
  beforeMount() {
    this.loadEntries();
  },
};
</script>