<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <div v-if="this.authentificated">
      <v-list-item two-line class="pa-3">
        <div class="pr-3">
          <v-avatar size="50" color="primary"
            ><span class="white--text text-h5">{{ user.short }}</span></v-avatar
          >
        </div>
        <v-divider vertical></v-divider>
        <v-list-item-content class="ma-3">
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle>
            <a class="exitlink" @click="this.logout">вийти</a>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item link @click="$router.push('/')">
          <v-list-item-action>
            <v-icon>mdi-desktop-mac-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Робочий стіл</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push('/clients')">
          <v-list-item-action>
            <v-icon>mdi-account-box-multiple-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Клієнти</v-list-item-title>
          </v-list-item-content>
        </v-list-item>        
        <v-list-item link @click="$router.push('/offers')">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Пропозиції</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <div v-else>
      <div class="ma-3 text-centred">
        <v-btn block outlined color="red"> авторизація </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "ls-drawer",
  props: {
    drawer: {
      type: Boolean,
    },
    authentificated: {
      type: Boolean,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      insystem: true,
    };
  },
  methods: {
    logout() {
      this.$emit('logout',)
    },
  },
  computed: {},
};
</script>

<style scoped>
.exitlink {
  text-decoration: none;
  margin-top: 3px;
  color: grey;
}
</style>