<template>
  <div>
    <h1>Контрагенти</h1>
    <div>
      <v-text-field
        v-model="searchPhraseMethod"
        label="почніть пошук кліента тут..."
        hide-details="auto"
      ></v-text-field>
    </div>
    <v-container class="clientHolder">
      <ls-client-card
        class="itemclient"
        v-for="client in filtredclients"
        :key="client.id"
        v-bind:client="client"
      ></ls-client-card>
    </v-container>
    <v-btn @click="addClient" color="primary" fab large class="v-btn-fab">
      <v-icon>mdi-account-plus</v-icon>
    </v-btn>
    <ls-client-addition
      ref="clientForm"
      :client="clientTemplate"
      @saveNewClient="saveNewClient"
      @closeDialog="closeDialog"
      v-bind:dialog="dialogaddition"
    ></ls-client-addition>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      searchPhrase: "",
      clients: [],
      filtredclients: [],
      dialogaddition: false,
      clientTemplate: {},
    };
  },
  methods: {
    closeDialog() {
      this.dialogaddition = false;
    },
    async saveNewClient(client) {
      let resp = await axios.post(
        process.env.VUE_APP_API_ADDRESS + "/api/clients/client",
        {
          name: client.name,
          phone: client.phone,
          email: client.email,
          dbcode: client.dbcode,
          recordid: client.id,
          discount: client.discount,
        },
        {
          headers: {
            Authorization: localStorage.getItem("lstoken"),
          },
        }
      );
      if (resp.status == 200) {
        this.dialogaddition = false;
        // console.log(resp.data)
        this.clients.push(resp.data.client);
        this.searchPhrase = "";
        this.showItems();
      }
    },
    async fetchClients() {
      let clientsreq = await axios.get(
        process.env.VUE_APP_API_ADDRESS + "/api/clients/clients",
        {
          headers: {
            authorization: localStorage.getItem("lstoken"),
          },
        }
      );
      if (clientsreq.status == 200) {
        this.clients = clientsreq.data;
        this.searchPhrase = "";
        this.showItems();
      }
    },
    addClient() {
      //this.$ref.clientForm.client = {}
      this.clientTemplate = {}
      this.$refs.clientForm.resetForm()
      this.dialogaddition = true;
    },
    showItems() {
      if (this.searchPhrase == "") {
        this.filtredclients = this.clients.map(function (item) {
          return item;
        });
      } else {
        this.filtredclients = this.clients.filter((item) => {
          if (
            item.name.toLowerCase().includes(this.searchPhrase.toLowerCase())
          ) {
            return item;
          }
          if (
            item.email.toLowerCase().includes(this.searchPhrase.toLowerCase())
          ) {
            return item;
          }
          if (
            item.phone.toLowerCase().includes(this.searchPhrase.toLowerCase())
          ) {
            return item;
          }
          if (
            item.dbcode
              .toString()
              .toLowerCase()
              .includes(this.searchPhrase.toLowerCase())
          ) {
            return item;
          }
        });
      }
    },
  },
  computed: {
    searchPhraseMethod: {
      get() {
        return this.searchPhrase;
      },
      set(newVal) {
        this.searchPhrase = newVal;
        this.showItems();
      },
    },
  },
  beforeMount() {
    this.$emit("checkAuth", "clients");
    this.fetchClients();
  },
};
</script>

<style scoped>
.clientHolder {
  display: flex;
  flex-wrap: wrap;
}

.itemclient {
  display: flex;
  flex-wrap: wrap;
}

.v-btn-fab {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 10;
}
</style>
