<template>
  <div>
    <ls-snackbar ref="snackbarComponent" />
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar color="primary" dark flat>
          <v-btn icon dark @click="cancelCreation">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Нова Пропозиція</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="createOffer(true)"> Опублікувати </v-btn>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-btn dark text @click="createOffer(false)"> Зберегти </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <ls-selection-items ref="itemsComponent">
            <v-row>
              <v-col cols="8">
                <ls-client-drop-down ref="selectedClient">
                </ls-client-drop-down>
              </v-col>
              <v-col cols="4">
                <v-slider
                  v-model="offertime"
                  :tick-labels="ticksLabels"
                  :max="4"
                  step="1"
                  ticks="always"
                  tick-size="5"
                  label="час дії пропозиції"
                  class="align-center mt-3"
                ></v-slider>
              </v-col>
            </v-row>
          </ls-selection-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ls-offer-creation",
  props: {
    dialog: {
      type: Boolean,
    },
  },
  watch:{
  },
  data() {
    return {
      currentClient: "",
      currentItems: "",
      ticksLabels: [
          '1',
          '3',
          '6',
          '12',
          '24',
        ],
      offertime:"",
    };
  },
  methods: {
    resetWindow() {
      console.log("called reset window");
    },
    changeClient(selectedClient) {
      this.currentClient = selectedClient;
    },
    cancelCreation() {
      this.$emit("cancelOffer");
    },
    validateOffer() {
      if (this.currentItems.length == 0) {
        this.$refs.snackbarComponent.showSnackbar(
          "неможливо створити пропозицію без товарів, оберіть іграшки та повторіть пробу"
        );
        return false;
      }
      if (this.currentClient == "") {
        this.$refs.snackbarComponent.showSnackbar(
          "вкажіть клієнта та повторіть спробу"
        );
        return false;
      }
      return true;
    },
    createOffer(publish) {
      this.currentClient = this.$refs.selectedClient.currentClient;
      this.currentItems = this.$refs.itemsComponent.itemsinpropose;
      if (this.validateOffer()) {
        let currentOffer = {
          items: this.currentItems,
          client: this.currentClient,
          lifetime: Number(this.ticksLabels[this.offertime])*60,
          published: publish,
        };
        this.$emit("createOffer", currentOffer);
      }
    },
  },
};
</script>

<style scoped>
.v-toolbar {
  max-height: 63px;
  min-height: 63px;
}
</style>