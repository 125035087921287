<template>
  <v-row justify="center">
    <v-overlay :z-index="zIndex" :value="this.show">
      <v-dialog v-model="dialog" persistent max-width="500px" min-width="360px">
        <div>
          <v-tabs v-model="tab" show-arrows icons-and-text dark grow>
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="i in tabs" :key="i.id">
              <v-icon large>{{ i.icon }}</v-icon>
            </v-tab>
            <v-tab-item>
              <v-card class="px-4">
                <v-card-text>
                  <v-form ref="loginForm" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="loginEmail"
                          :rules="loginEmailRules"
                          label="E-mail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="loginPassword"
                          :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                          :rules="[rules.required, rules.min]"
                          :type="show1 ? 'text' : 'password'"
                          name="input-10-1"
                          label="Пароль"
                          hint="мінімум 8 символів"
                          counter
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="6" xsm="12"></v-col>
                      <v-spacer></v-spacer>
                      <v-col class="d-flex" cols="12" sm="5" xsm="12" align-end>
                        <v-btn
                          x-large
                          block
                          :disabled="!valid"
                          color="success"
                          @click="validate"
                        >
                          Авторизація
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="px-4">
                <v-card-text>
                  <v-form ref="registerForm" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="regName"
                          :rules="[rules.required]"
                          label="Імя та прізвище"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="loginEmail"
                          :rules="loginEmailRules"
                          label="E-mail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="regPartnerNumber"
                          :rules="numberRules"
                          label="Номер підрозілу"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="regPassword"
                          :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                          :rules="[rules.required, rules.min]"
                          :type="show1 ? 'text' : 'password'"
                          name="input-10-1"
                          label="Пароль"
                          hint="мінімум 8 символів"
                          counter
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="regSecret"
                          name="input-10-3"
                          label="Ключ адміна"
                          hint="вставте ключ доступа адміністратора системи"
                          :rules="[rules.required]"
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="6" xsm="12"></v-col>
                      <v-spacer></v-spacer>
                      <v-col class="d-flex" cols="12" sm="5" xsm="12" align-end>
                        <v-btn
                          x-large
                          block
                          :disabled="!valid"
                          color="success"
                          @click="regvalidate"
                        >
                          Реєстрація
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-dialog>
    </v-overlay>
  </v-row>
</template>

<script>
export default {
  name: "ls-auth-dialog",
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      zIndex: 0,
      dialog: true,
      tab: 0,
      tabs: [
        { id: 0, name: "", icon: "mdi-account" },
        { id: 1, name: "", icon: "mdi-account-plus" },
      ],
      valid: true,
      verify: "",
      regName: "",
      regSecret: "",
      loginPassword: "",
      regPassword: "",
      regPartnerNumber : "",
      loginEmail: "",
      loginEmailRules: [
        (v) => !!v || "Обов`язкове поле.",
        (v) => /.+@.+\..+/.test(v) || "введіть дійсний E-mail",
      ],

      numberRules: [
        (v) => !!v || "Обов`язкове поле.",
        (v) => /^\d+$/.test(v) || "введіть номер підрозділу тільки числом",
      ],

      show1: false,
      rules: {
        required: (value) => !!value || "Обов`язкові поля.",
        min: (v) => (v && v.length >= 8) || "мінімум 8 символів",
      },
    };
  },
  computed: {},
  methods: {
    resetForm() {
      this.regName = ""
      this.loginPassword = ""
      this.regSecret = ""
      this.loginEmail = ""
      this.regPassword = ""
      this.regPartnerNumber = ""
    },
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.$emit("login", {
          login: this.loginEmail,
          password: this.loginPassword,
        });
      }
    },
    regvalidate() {
      if (this.$refs.registerForm.validate()) {
        this.$emit("register", {
          name: this.regName,
          login: this.loginEmail,
          partner: this.regPartnerNumber,
          password: this.regPassword,
          secretPhrase: this.regSecret,
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
