import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import components from '@/components'
import VueRouter from 'vue-router'
import router from '@/router/router'
import { VueMaskDirective } from 'v-mask'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

require('dotenv').config()
Vue.directive('mask', VueMaskDirective);
Vue.use(VueRouter);

const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
 
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }
   
        return Promise.reject(err)
    })
}

Vue.config.productionTip = false
Vue.component('vue-custom-scrollbar',vueCustomScrollbar);
components.forEach(component => {
  Vue.component(component.name, component);
});

const app = new Vue({
  router,
  vuetify,
  render: h => h(App)
});

app.$mount('#app');