import Main from '@/pages/Main.vue'
import Offers from '@/pages/Offers.vue'
import Clients from '@/pages/Clients.vue'
import VueRouter from 'vue-router'

const routes = [{
    path: '/',
    component: Main,
}, {
    path: '/offers',
    component: Offers,
}, {
    path: '/clients',
    component: Clients,
}]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router