<template>
  <div>
    <ls-snackbar ref="snackbarComponent" />
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar color="primary" dark flat>
          <v-btn icon dark @click="closeOfferView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Перегляд пропозиції: #{{ getOfferId() }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="closeOfferView"> Закрити </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <div class="contantcontainer">
            <div class="infocontainer">
              <div class="text-subtitle-1">
                {{ getClientInfo("name") }}
              </div>
              <div class="text-subtitle-2">
                {{ getClientInfo("email") }}
              </div>
              <div class="text-subtitle-2">
                {{ getClientInfo("phone") }}
              </div>
            </div>
            <vue-custom-scrollbar>
              <div class="itemscontainer">
                <div v-for="item in itemsinoffer" :key="item.id">
                  <v-card
                    hover
                    class="item mx-auto"
                    max-width="400"
                    min-width="300"
                  >
                    <v-card-text>
                      <v-img :aspect-ratio="6 / 4" :src="getFirstImage(item)" />
                      <div>{{ item.articul }}</div>
                      <p class="text-h5 text--primary">{{ item.code }}</p>
                      <div class="text--primary">
                        Замовлено: {{ item.order }}шт.<br />
                      </div>
                    </v-card-text>
                    <v-card-actions> </v-card-actions>
                  </v-card>
                </div>
              </div>
            </vue-custom-scrollbar>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      requred: true,
    },
    dialog: {
      type: Boolean,
    },
  },
  name: "ls-offer-view",
  data() {
    return {};
  },
  computed: {
    itemsinoffer() {
      if (this.offer) {
        return this.offer.items.map((element) => {
          return element;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    getFirstImage(item) {
      try {
        return (
          "https://xn--80axep4eb.xn--j1amh/public/images/" + item.images[0]
        );
      } catch (err) {
        console.log(err);
      }
    },
    closeOfferView() {
      this.$emit("closeOfferView");
    },
    getItems() {
        return this.offer.items.forEach((element) => {
          return element;
        });
    },
    getClientInfo(prop) {
      if (this.offer) {
        switch (prop) {
          case "name":
            return this.offer.client.name;
          case "phone":
            return this.offer.client.phone;
          case "email":
            return this.offer.client.email;
          default:
            return this.offer.client.name;
        }
      }
    },
    getOfferId() {
      if (this.offer) {
        return this.offer.id;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-toolbar {
  max-height: 63px;
  min-height: 63px;
}
.contantcontainer {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .infocontainer {
    height: 80px;
  }

  .itemscontainer {
    flex: 1;
    display: flex;
    padding: 10px;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .item {
    margin: 5px;
    display: flex;
  }
}
</style>