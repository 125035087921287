<template>
  <div id="app">
    <ls-auth-dialog
      :show="authdialog"
      @login="onLogin"
      @register="onRegister"
      ref="authComponent"
    />
    <ls-snackbar ref="snackbarComponent" />
    <v-app id="inspire">
      <ls-drawer
        @logout="onLogout"
        v-bind:authentificated="authentificated"
        v-bind:drawer="drawer"
        v-bind:user="currentuser"
      />
      <v-app-bar app clipped-left>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Масяня ТМ - Landing store creator</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn @click="changeTheme" icon>
          <v-icon>mdi-invert-colors</v-icon>
        </v-btn>
      </v-app-bar>
        <v-main class="parent mt-10">
          <div class="contentholder pa-3">
            <ls-auth-dialog></ls-auth-dialog>
            <router-view @checkAuth="checkAuth" />
          </div>
        </v-main>
      <v-footer app>
        <span>&copy; Масяня ТМ {{ new Date().getFullYear() }}</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import AuthentificationController from "@/controllers/AuthentificationController";
export default {
  name: "App",
  props: {
    source: String,
  },

  data() {
    return {
      drawer: true,
      authentificated: false,
      currentuser: {
        name: "",
        short: "",
      },
      authdialog: false,
    };
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("lsthemedark", this.$vuetify.theme.dark);
    },
    async onRegister(authdata) {
      let allok = await AuthentificationController.register(
        authdata.name,
        authdata.login,
        authdata.partner,
        authdata.password,
        authdata.secretPhrase
      );
      if (allok) {
        this.$refs.authComponent.resetForm();
        this.$refs.snackbarComponent.showSnackbar("новий користувач створено");
      } else {
        this.$refs.snackbarComponent.showSnackbar(
          "сталась помилка при створенні нового користувача"
        );
      }
    },
    async onLogin(authdata) {
      let allok = await AuthentificationController.login(
        authdata.login,
        authdata.password
      );
      if (allok != false) {
        this.currentuser = AuthentificationController.user;
        this.authentificated = AuthentificationController.authentificated;
        this.authdialog = !this.authentificated;
      } else {
        this.$refs.snackbarComponent.showSnackbar(
          "хибні авторизаційні дані, перевірте та повторіть спробу знову"
        );
      }
    },

    async checkAuth() {
      let allok = await AuthentificationController.checkAuth();
      if (!allok) {
        this.currentuser = AuthentificationController.user;
        this.authentificated = AuthentificationController.authentificated;
        this.authdialog = true;
      } else {
        this.currentuser = AuthentificationController.user;
        this.authentificated = AuthentificationController.authentificated;
        this.authdialog = false;
      }
    },
    async onLogout() {
      this.authentificated = false;
      this.currentuser = {
        login: "",
        name: "",
        short: "",
      };
      this.authdialog = true;
    },
  },
  beforeMount() {
    this.checkAuth();
    let themeDark = localStorage.getItem("lsthemedark");
    if (themeDark != null) {
      this.$vuetify.theme.dark = JSON.parse(themeDark);
    } else {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("lsthemedark", this.$vuetify.theme.dark);
    }
  },
};
</script>

<style>
</style>