<template>
  <div>
    <ls-snackbar ref="snackbarComponent" />
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar color="primary" dark flat>
          <v-btn icon dark @click="cancelCreation">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Копія пропозиції: #{{ getOfferId() }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="updateOffer(true)">
              Копіювати та опублікувати
            </v-btn>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-btn dark text @click="updateOffer(false)"> Копіювати </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <ls-selection-items v-bind:offerItems="offer" ref="itemsComponent"
            ><v-row>
              <v-col cols="8">
                <ls-client-drop-down ref="selectedClient">
                </ls-client-drop-down>
              </v-col>
              <v-col cols="4">
                <v-slider
                  v-model="offertime"
                  :tick-labels="ticksLabels"
                  :max="4"
                  step="1"
                  ticks="always"
                  tick-size="5"
                  label="час дії пропозиції"
                  class="align-center mt-3"
                ></v-slider>
              </v-col> </v-row
          ></ls-selection-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ls-offer-copy",
  props: {
    offer: {
      type: Object,
    },
    dialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentClient: "",
      currentItems: "",
      dataloaded: false,
      ticksLabels: ["1", "3", "6", "12", "24"],
      offertime: "",
    };
  },
  methods: {
    getCurrentClient() {
      if (this.offer) {
        //console.log(this.offer.client)
        return this.offer.client;
      }
    },
    getOfferId() {
      if (this.offer) {
        return this.offer.id;
      } else {
        return 0;
      }
    },
    changeClient(selectedClient) {
      this.currentClient = selectedClient;
    },
    cancelCreation() {
      this.$emit("cancelUpdateOffer");
    },
    validateOffer() {
      if (this.currentItems.length == 0) {
        this.$refs.snackbarComponent.showSnackbar(
          "неможливо створити пропозицію без товарів, оберіть іграшки та повторіть пробу"
        );
        return false;
      }
      if (this.currentClient == "") {
        this.$refs.snackbarComponent.showSnackbar(
          "вкажіть клієнта та повторіть спробу"
        );
        return false;
      }
      return true;
    },
    async getItemsFromIstok(itemId) {
      const connectionString =
        process.env.VUE_APP_ISTOK_ADDRESS + "/api/v1/toys?code=" + itemId;
      try {
        const response = await axios.get(connectionString, {
          headers: {
            "x-access-token":
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InZvbG9keW15ci5zaHlua2Fyb3YifQ.VWIINqStn-pXrqoJZ3FHhP7I2N11fp-_ifls-PEsdhg",
          },
        });
        //console.log(response.data.data[0])
        return response.data.data[0];
      } catch (err) {
        console.log(err);
      }
    },
    async updateOffer(publish) {
      this.currentClient = this.$refs.selectedClient.currentClient;
      this.currentItems = this.$refs.itemsComponent.itemsinpropose;
      if (this.validateOffer()) {
        let currentOffer = {
          items: this.currentItems,
          client: this.currentClient,
          lifetime: Number(this.ticksLabels[this.offertime])*60,
          id: this.offer.id,
          published: publish,
        };
        if (publish) {
          this.$emit("createOffer", currentOffer);
        } else {
          this.$emit("createOffer", currentOffer);
        }
      }
    },
  },
};
</script>

<style scoped>
.v-toolbar {
  max-height: 63px;
  min-height: 63px;
}
</style>