<template>
  <div>
    <h1>Пропозиції</h1>
    <v-container fluid class="wrapper flex-row">
      <ls-offer-creation
        ref="creationOfferDialog"
        v-bind:dialog="creationDialog"
        @createOffer="createOffer"
        @cancelOffer="creationDialog = false"
      ></ls-offer-creation>
      <ls-offer-edition
        :offer="offerForEditing"
        v-bind:dialog="updateDialog"
        @updateOffer="updateOffer"
        @cancelUpdateOffer="updateDialog = false"
      >
      </ls-offer-edition>
      <ls-offer-copy
        :offer="offerForCopy"
        v-bind:dialog="copyDialog"
        @createOffer="createOffer"
        @cancelUpdateOffer="copyDialog = false"
      >
      </ls-offer-copy>
      <ls-offer-view
        :offer="offerForView"
        v-bind:dialog="viewDialog"
        @closeOfferView="viewDialog = false"
      >
      </ls-offer-view>
    </v-container>
    <v-btn @click="addOffer" color="primary" fab large class="v-btn-fab">
      <v-icon>mdi-basket-plus</v-icon>
    </v-btn>
    <ls-offer-list
      v-bind="offerForEditing"
      @editOffer="editOffer"
      @copyOffer="copyOffer"
      @publishOffer="publishOffer"
      ref="offersList"
    ></ls-offer-list>
  </div>
</template>

<script>
import axios from "axios";
import AuthentificationController from "@/controllers/AuthentificationController";
export default {
  name: "App",

  data() {
    return {
      creationDialog: false,
      updateDialog: false,
      copyDialog: false,
      viewDialog: false,
      offerForEditing: null,
      offerForCopy: null,
      offerForView: null,
    };
  },

  methods: {
    editOffer(offerForEditing) {
      if (offerForEditing.finished && offerForEditing.published) {
        this.offerForView = offerForEditing;
        this.viewDialog = true;
      } else if (!offerForEditing.finished && offerForEditing.published) {
        this.offerForView = offerForEditing;
        this.viewDialog = true;
      } else {
        this.offerForEditing = offerForEditing;
        this.updateDialog = true;
      }
    },
    copyOffer(offerForEditing) {
      this.offerForCopy = offerForEditing;
      this.copyDialog = true;
    },
    addOffer() {
      this.creationDialog = true;
    },
    publishOffer(offerForEditing) {
      offerForEditing.published = true;
      this.updateOffer(offerForEditing, true);
    },
    scalpItems(rawItemsArray, sameArray) {
      if (sameArray == true) {
        return rawItemsArray;
      } else {
        return rawItemsArray.map((element) => {
          let current_price = '';
          if (AuthentificationController.user.partnerid == 2) {
              if (element.priceusd == null) {
                  current_price = element.pricemiddle;
              } else {
                  current_price = element.priceusd*40.50;
              }
          } else {
              current_price = element.price;
          }
          return {
            id: element.id,
            code: element.code,
            maxquantity: element.maximumquantity,
            images: element.image,
            price: current_price,
            name: element.name,
            articul: element.articul,
            order: 0,
          };
        });
      }
    },
    async updateOffer(offerObject, sameArray = false) {
      let resp = await axios.post(
        process.env.VUE_APP_API_ADDRESS + "/api/offers/update",
        {
          clientcode: offerObject.client.id,
          discount: offerObject.client.discount,
          items: this.scalpItems(offerObject.items, sameArray),
          lifetime: offerObject.lifetime,
          offerId: offerObject.id,
          published: offerObject.published,
        },
        {
          headers: {
            authorization: localStorage.getItem("lstoken"),
          },
        }
      );
      if (resp.status == "200") {
        this.updateDialog = false;
        this.$refs.offersList.fetchOffers();
      }
    },
    async createOffer(offerObject) {
      let resp = await axios.post(
        process.env.VUE_APP_API_ADDRESS + "/api/offers/offer",
        {
          clientcode: offerObject.client.id,
          discount: offerObject.client.discount,
          items: this.scalpItems(offerObject.items),
          lifetime: offerObject.lifetime,
          published: offerObject.published,
        },
        {
          headers: {
            authorization: localStorage.getItem("lstoken"),
          },
        }
      );
      if (resp.status == "200") {
        this.creationDialog = false;
        this.copyDialog = false;
        this.$refs.offersList.fetchOffers();
      }
    },
  },
  beforeMount() {
    this.$emit("checkAuth", "offers page");
  },
};
</script>

<style scoped lang='scss'>
.v-btn-fab {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 10;
}
</style>
