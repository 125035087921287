<template>
  <v-card
    :max-width="380"
    class="mx-auto offeritem"
    :loading="loading ? getPrimaryColor() : null"
    hover
    :ripple="{ class: getRippleColor() }"
    @click.prevent="openOffer()"
  >
    <v-progress-linear
      :active="showprogress"
      v-model="progressvalue"
      absolute
      top
      striped
      height="7"
      color="green accent-8"
    ></v-progress-linear>
    <v-list-item three-line dense>
      <v-list-item-avatar size="60" :color="getPrimaryColor()">
        <v-icon color="white" size="40">{{
          getPrimaryIcon()
        }}</v-icon></v-list-item-avatar
      >
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ offer.client.name }}
        </v-list-item-title>
        <v-card-text>
          <v-row no-gutters>
            <v-rating
              readonly
              dense
              background-color="grey lighten-2"
              color="warning"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              length="5"
              size="30"
              :value="getRating()"
            ></v-rating>
          </v-row>
          <v-row>
            <p dense>
              <v-icon size="20" class="green--text">mdi-pen-plus</v-icon>
              {{ getDateCreation() }}
            </p>
          </v-row>
          <v-row v-if="offer.publishtime">
            <p dense>
              <v-icon size="20" class="teal--text">mdi-earth</v-icon>
              {{ getDatePublish() }}
            </p>
          </v-row>
        </v-card-text>
      </v-list-item-content>
    </v-list-item>
    <v-spacer></v-spacer>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            link
            @click="checkClick(item)"
          >
            <v-list-item-icon>
              <v-icon :color="item.color" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <ls-snackbar ref="snackbarComponent" />
  </v-card>
</template>

<script>
const copy = require("clipboard-copy");
export default {
  name: "ls-offer-item",
  props: {
    offer: {
      type: Object,
    },
  },
  computed: {
    items() {
      let variants = [];
      if (this.offer.finished) {
        variants.push({
          title: "Відкрити",
          icon: "mdi-folder-open-outline",
          color: "teal",
        });
        variants.push({
          title: "Копіювати",
          icon: "mdi-content-copy",
          color: "teal",
        });
      }
      if (this.offer.published && !this.offer.finished) {
        variants.push({
          title: "Відкрити",
          icon: "mdi-folder-open-outline",
          color: "teal",
        });
        variants.push({
          title: "Копіювати",
          icon: "mdi-content-copy",
          color: "teal",
        });
        if (new Date(this.offer.spoiltime) > new Date()) {
          variants.push({
            title: "Посилання на пропозицію",
            icon: "mdi-link",
            color: "red",
          });
        }
      }
      if (!this.offer.published && !this.offer.finished) {
        variants.push({
          title: "Відкрити",
          icon: "mdi-folder-open-outline",
          color: "teal",
        });
        variants.push({
          title: "Копіювати",
          icon: "mdi-content-copy",
          color: "teal",
        });
        variants.push({
          title: "Опублікувати",
          icon: "mdi-earth",
          color: "teal",
        });
      }
      return variants;
    },
  },
  watch: {
    offer() {
      if (this.offer) {
        this.setColorByState();
        //this.items();
      }
    },
    "offer.publishtime"() {
      this.spoilDuration();
    },
  },
  data() {
    return {
      loading: false,
      currentColorIndex: 5,
      darkmode: false,
      progressvalue: 0,
      interval: 0,
      showprogress: false,
    };
  },
  beforeMount() {
    this.setColorByState();
    this.spoilDuration();
  },
  methods: {
    spoilDuration() {
      let duration = new Date(this.offer.spoiltime) - new Date();
      let completeduration =
        new Date(this.offer.spoiltime) - new Date(this.offer.publishtime);
      if (duration > 0 && !this.offer.finished) {
        this.showprogress = true;
        this.interval = setInterval(() => {
          let curDuration = new Date(this.offer.spoiltime) - new Date();
          this.progressvalue = (curDuration / completeduration) * 100;
          if (curDuration < 0) {
            this.showprogress = false;
            clearInterval(this.interval);
            this.setColorByState();
          }
        }, 1000);
      }
    },
    checkClick(item) {
      switch (item.title.toLowerCase()) {
        case "відкрити":
          this.openOffer();
          break;
        case "копіювати":
          this.copyOffer();
          break;
        case "опублікувати":
          this.publishOffer();
          break;
        case "посилання на пропозицію":
          this.copylink();
          break;
        default:
          break;
      }
    },

    getAllOrderedItemsInOffer() {
      let counter = 0;
      for (let i = 0; i < this.offer.items.length; i++) {
        const element = this.offer.items[i];
        if (element.order > 0) {
          counter++;
        }
      }
      return counter;
    },
    getRating() {
      let allOrderedItems = this.getAllOrderedItemsInOffer();
      let allItems = this.offer.items.length;
      let percent = (allOrderedItems / allItems) * 5;
      percent = Math.round(percent * 2) / 2;
      if (allOrderedItems == 0) {
        return 0;
      } else {
        return percent;
      }
    },
    getStat() {
      let allOrderedItems = this.getAllOrderedItemsInOffer();
      let allItems = this.offer.items.length;
      return allItems + "/" + allOrderedItems;
    },
    getDatePublish() {
      return new Date(this.offer.publishtime).toLocaleDateString("uk-UA");
    },
    getDateCreation() {
      //let date = new Date(this.offer.createdAt);
      return new Date(this.offer.createdAt).toLocaleDateString("uk-UA");
    },
    setColorByState() {
      if (this.offer.finished) {
        this.currentColorIndex = 1;
        return;
      }
      if (this.offer.spoiltime) {
        if (new Date(this.offer.spoiltime) < new Date()) {
          this.currentColorIndex = 3;
          return;
        }
      }
      if (this.offer.published) {
        this.currentColorIndex = 2;
        return;
      }
      this.currentColorIndex = 0;
    },
    getPrimaryColor() {
      const rippleColors = [
        "grey", //regular
        "orange", //finished
        "green", //published
        "red", //spoiled
      ];
      return rippleColors[this.currentColorIndex];
    },
    getPrimaryIcon() {
      const icons = [
        "mdi-new-box", //regular
        "mdi-handshake-outline ", //finished
        "mdi-earth", //published
        "mdi-robot-dead-outline", //spoiled
      ];
      return icons[this.currentColorIndex];
    },
    getRippleColor() {
      const rippleColors = [
        "grey--text",
        "orange--text",
        "green--text",
        "red--text",
      ];
      return rippleColors[this.currentColorIndex];
    },
    openOffer() {
      this.loading = false;
      let offlineOffer = JSON.parse(JSON.stringify(this.offer));
      this.$emit("editOffer", offlineOffer);
    },
    copyOffer() {
      this.loading = false;
      let offlineOffer = JSON.parse(JSON.stringify(this.offer));
      this.$emit("copyOffer", offlineOffer);
    },
    copylink() {
      copy(
        process.env.VUE_APP_FRONT_STORE_ADDRESS + "/offer/" + this.offer.link
      );
      this.$refs.snackbarComponent.showSnackbar(
        "посилання скопійоване в буфер обміну, просто перешліть його клієнту"
      );
    },
    publishOffer() {
      let offlineOffer = JSON.parse(JSON.stringify(this.offer));
      this.$emit("publishOffer", offlineOffer);
    },
    getItemsQuantity() {
      return this.offer.items.length;
    },
    getOrderedItems() {
      let count = 0;
      for (let iter = 0; iter < this.offer.items.length; iter++) {
        const element = this.offer.items[iter];
        if (element.order > 0) count++;
      }
      return count;
    },
  },
};
</script>

<style scoped>
.offeritem {
  display: flex;
  padding: 15px;
  margin: 5px;
}
</style>